@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// rtl Direction
// @import "./custom/rtl/general-rtl";
// @import "./custom/rtl/bootstrap-rtl";
// @import "./custom/rtl/spacing-rtl";
// @import "./custom/rtl/float-rtl";
// @import "./custom/rtl/text-rtl";
// @import "./custom/rtl/structure-rtl";
// @import "./custom/rtl/plugins-rtl";
// @import "./custom/rtl/components-rtl";
// @import "./custom/rtl/pages-rtl";


body {
  * {
    outline: none;
  }
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}
.ql-snow{
  .ql-picker{
      &.ql-size{
          .ql-picker-label,
          .ql-picker-item{
              &::before{
                  content: attr(data-value) !important;
              }
          }
      }
  }
  }

.ql-size-8px { font-size: 8px; }
.ql-size-9px { font-size: 9px; }
.ql-size-10px { font-size: 10px; }
.ql-size-11px { font-size: 11px; }
.ql-size-12px { font-size: 12px; }
.ql-size-14px { font-size: 14px; }
.ql-size-16px { font-size: 16px; }
.ql-size-18px { font-size: 18px; }
.ql-size-20px { font-size: 20px; }
.ql-size-24px { font-size: 24px; }
.ql-size-28px { font-size: 28px; }
.ql-size-32px { font-size: 32px; }
.ql-size-36px { font-size: 36px; }
.ql-size-40px { font-size: 40px; }
.ql-size-48px { font-size: 48px; }
.ql-size-56px { font-size: 56px; }
.ql-size-64px { font-size: 64px; }
.ql-size-72px { font-size: 72px; }
.ql-size-96px { font-size: 96px; }
